<template>
  <c-box id="listNutritionists">
    <Header logo="hide" :back="true" back-title="List Ahli Gizi" />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box
        w="100%"
        maxWidth="1200px"
        mx="auto"
        v-chakra="{
          '> nav': {
            '@media(max-width: 767px)': {
              display: 'none !important',
            },
          },
        }"
      >
        <Breadcrumb
          :items="[
            { href: '/', label: 'Beranda' },
            { label: 'Ahli Gizi', isCurrent: true },
          ]"
        />

        <c-box
          w="100%"
          :maxWidth="['inherit', '1200px']"
          mx="auto"
          :marginTop="['20px', '50px']"
          :display="['flex', 'block']"
          :borderBottom="['1px solid #F2F2F2', 'none']"
          :paddingBottom="['15px', '0']"
          px="20px"
        >
          <c-flex justifyContent="space-between">
            <c-heading
              :display="['none', 'block']"
              fontSize="24px"
              lineHeight="32px"
              color="black.900"
            >
              List Ahli Gizi
            </c-heading>
            <c-menu :close-on-select="false">
              <c-menu-button
                borderRadius="20px"
                backgroundColor="superLightGray.900"
                px="15px"
                py="4px"
                color="gray.900"
                fontSize="16px"
                lineHeight="24px"
                fontFamily="Roboto"
                fontWeight="700"
                :display="['none', 'flex']"
              >
                <c-image
                  :src="require('@/assets/ic-filter.svg')"
                  alt="icon"
                  display="inline-block"
                  marginRaight="5px"
                />
                Filter
              </c-menu-button>
              <c-menu-list
                z-index="10"
                min-width="240px"
                max-h="350px"
                overflow-y="auto"
              >
                <c-menu-option-group
                  @change="onChangeSpecialization"
                  default-value="Semua spesialisasi"
                  type="radio"
                >
                  <c-menu-item-option
                    v-for="item in listFilter"
                    :key="item"
                    :value="item"
                    border-bottom="1px solid #F2F2F2"
                    font-size="18px"
                    line-height="27px"
                    py="16px"
                    px="20px"
                  >
                    {{ item }}
                  </c-menu-item-option>
                </c-menu-option-group>
              </c-menu-list>
            </c-menu>
          </c-flex>
          <c-box
            :w="['100%', 'auto']"
            :marginTop="['0', '30px']"
            :marginBottom="['0', '30px']"
          >
            <c-form-control pos="relative" w="100%">
              <c-image
                :src="require('@/assets/ic-search.svg')"
                alt="icon"
                pos="absolute"
                top="10px"
                left="15px"
                zIndex="2"
              />
              <c-input
                type="text"
                w="100%"
                id="searchId"
                placeholder="Pencarian"
                background="superLightGray.900"
                :borderRadius="['6px', '12px']"
                paddingLeft="50px"
                pos="relative"
                zIndex="1"
                @keyup="onSearch"
                v-model="search"
                v-chakra="{
                  '&::placeholder': {
                    color: 'gray.900',
                  },
                }"
              />
            </c-form-control>
          </c-box>
          <c-button
            borderRadius="0"
            backgroundColor="transparent"
            px="15px"
            py="4px"
            color="gray.900"
            fontSize="16px"
            lineHeight="24px"
            fontFamily="Roboto"
            fontWeight="700"
            :display="['inline-block', 'none']"
            @click.prevent="isMobileFilter = true"
          >
            <c-image
              :src="require('@/assets/ic-filter.svg')"
              alt="icon"
              display="inline-block"
              marginRaight="5px"
            />
          </c-button>
        </c-box>
        <c-box
          v-if="recommendationNutritionists.length > 0"
          marginBottom="40px"
          px="20px"
          :borderBottom="['1px solid #F2F2F2', 'none']"
        >
          <c-box
            w="100%"
            marginBottom="20px"
            :borderBottom="['none', '1px solid #F2F2F2']"
          >
            <c-heading
              fontFamily="Roboto"
              fontSize="18px"
              fontWeight="500"
              color="superDarkGray.900"
              my="8px"
            >
              Ahli gizi yang pernah dipilih
            </c-heading>
          </c-box>
          <c-box>
            <c-grid
              w="100%"
              :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
              gap="6"
            >
              <c-box
                v-for="(item, index) in recommendationNutritionists"
                :key="index"
                w="100%"
                marginBottom="0"
                backgroundColor="white"
                :borderRadius="['6px', '12px']"
                border="1px solid #f2f2f2"
                box-shadow="0px 5px 30px 0px #0000000D"
                :p="[null, '26px']"
                as="router-link"
                :to="{
                  name: 'client.nutritionists.detail',
                  params: {
                    nutritionistId: item.id,
                    program: availableProgram,
                  },
                }"
              >
                <c-flex w="100%" :py="['13px', null]" :px="['20px', null]">
                  <c-box
                    :min-w="['50px', '96px']"
                    :w="['50px', '96px']"
                    :h="['50px', '96px']"
                    overflow="hidden"
                    borderRadius="100%"
                    backgroundColor="lightGray.900"
                    :mr="['20px', '30px']"
                  >
                    <c-image
                      object-fit="cover"
                      w="100%"
                      h="100%"
                      :src="getPhotoNutritionist(item.photoUrl)"
                      :alt="item.firstName"
                    />
                  </c-box>
                  <c-box w="100%">
                    <c-heading
                      as="h3"
                      :marginBottom="['5px', '15px']"
                      :fontSize="['14px', '18px']"
                      :lineHeight="['21px', '27px']"
                      fontWeight="700"
                      color="black.900"
                    >
                      {{ getFullnameTitle(item) }}
                      <c-box
                        :d="['none', 'inline-block']"
                        as="span"
                        :color="item.isAvailable ? 'brand.900' : 'red.900'"
                        borderWidth="1px"
                        borderStyle="1px solid"
                        :borderColor="
                          item.isAvailable ? 'brand.900' : 'red.900'
                        "
                        borderRadius="30px"
                        px="8px"
                        py="0px"
                        fontSize="12px"
                        fontWeight="500"
                        verticalAlign="middle"
                        marginLeft="8px"
                      >
                        {{ item.isAvailable ? "Available" : "Full" }}
                      </c-box>
                    </c-heading>
                    <c-text
                      v-if="item.specialization"
                      fontFamily="Roboto"
                      :fontSize="['12px', '16px']"
                      color="brand.900"
                      :marginBottom="['6px', '8px']"
                    >
                      {{ item.specialization }}
                    </c-text>
                    <c-box :mb="[null, '20px']">
                      <c-text
                        v-for="(item, index) in item.education"
                        :key="'edu' + index"
                        fontFamily="Roboto"
                        fontSize="14px"
                        color="darkGray.900"
                        text-transform="capitalize"
                      >
                        {{ item.level }} -
                        {{
                          item.university === "0"
                            ? item.otherUniversity
                            : getUniversityName(item.university)
                        }}
                      </c-text>
                    </c-box>
                    <c-box
                      :d="['none', 'flex']"
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <c-button
                        v-if="!item.whoHandle"
                        right-icon="arrow-forward"
                        backgroundColor="secondary.800"
                        color="#927102"
                        borderRadius="100px"
                        fontSize="16px"
                        px="20px"
                        :isDisabled="!availableProgram"
                        @click.prevent="onChooseNutritionist(item.id)"
                      >
                        Pilih
                      </c-button>
                      <c-button
                        v-if="item.whoHandle"
                        right-icon="check"
                        backgroundColor="#F2F2F2"
                        color="brand.900"
                        borderRadius="100px"
                        fontSize="16px"
                        px="20px"
                      >
                        Dipilih
                      </c-button>
                      <c-text
                        fontFamily="Roboto"
                        fontSize="16px"
                        color="gray.900"
                      >
                        <c-image
                          :src="require('@/assets/icon-star.svg')"
                          alt="icon"
                          display="inline-block"
                          verticalAlign="middle"
                          marginRight="0px"
                        />
                        {{ item.rating ? item.rating : "0" }}/5
                      </c-text>
                    </c-box>
                  </c-box>
                </c-flex>
                <c-flex
                  :d="['flex', 'none']"
                  border-top="1px solid #F2F2F2"
                  py="13px"
                  px="20px"
                >
                  <c-box
                    w="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <c-flex>
                      <c-flex
                        align="center"
                        borderWidth="1px"
                        borderStyle="1px solid"
                        :color="item.isAvailable ? 'brand.900' : 'red.900'"
                        :borderColor="
                          item.isAvailable ? 'brand.900' : 'red.900'
                        "
                        borderRadius="30px"
                        px="8px"
                        py="0px"
                        fontSize="12px"
                        fontWeight="500"
                        verticalAlign="middle"
                        mr="20px"
                      >
                        {{ item.isAvailable ? "Available" : "Full" }}
                      </c-flex>
                      <c-text
                        fontFamily="Roboto"
                        fontSize="16px"
                        color="gray.900"
                      >
                        <c-image
                          :src="require('@/assets/icon-star.svg')"
                          alt="icon"
                          display="inline-block"
                          verticalAlign="middle"
                          marginRight="0px"
                        />
                        {{ item.rating ? item.rating : "0" }}/5
                      </c-text>
                    </c-flex>
                    <c-button
                      v-if="!item.whoHandle"
                      right-icon="arrow-forward"
                      backgroundColor="secondary.800"
                      color="#927102"
                      borderRadius="100px"
                      fontSize="16px"
                      px="20px"
                      :isDisabled="!availableProgram"
                      @click.prevent="onChooseNutritionist(item.id)"
                    >
                      Pilih
                    </c-button>
                    <c-button
                      v-if="item.whoHandle"
                      right-icon="check"
                      backgroundColor="#F2F2F2"
                      color="brand.900"
                      borderRadius="100px"
                      fontSize="16px"
                      px="20px"
                    >
                      Dipilih
                    </c-button>
                  </c-box>
                </c-flex>
              </c-box>
            </c-grid>
          </c-box>
        </c-box>
        <c-box
          marginBottom="40px"
          px="20px"
          :borderBottom="['1px solid #F2F2F2', 'none']"
        >
          <c-box
            w="100%"
            marginBottom="20px"
            :borderBottom="['none', '1px solid #F2F2F2']"
          >
            <c-heading
              fontFamily="Roboto"
              fontSize="18px"
              fontWeight="500"
              color="superDarkGray.900"
              marginBottom="8px"
            >
              Ahli gizi tersedia
            </c-heading>
          </c-box>
          <c-box v-if="!isEmpty">
            <c-grid
              w="100%"
              :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
              gap="6"
            >
              <c-box
                v-for="(item, index) in nutritionists"
                :key="index"
                w="100%"
                marginBottom="0"
                backgroundColor="white"
                :borderRadius="['6px', '12px']"
                border="1px solid #f2f2f2"
                box-shadow="0px 5px 30px 0px #0000000D"
                :p="[null, '26px']"
                as="router-link"
                :to="{
                  name: 'client.nutritionists.detail',
                  params: {
                    nutritionistId: item.id,
                    program: availableProgram,
                  },
                }"
              >
                <c-flex w="100%" :py="['13px', null]" :px="['20px', null]">
                  <c-box
                    :min-w="['50px', '96px']"
                    :w="['50px', '96px']"
                    :h="['50px', '96px']"
                    overflow="hidden"
                    borderRadius="100%"
                    backgroundColor="lightGray.900"
                    :mr="['20px', '30px']"
                  >
                    <c-image
                      object-fit="cover"
                      w="100%"
                      h="100%"
                      :src="getPhotoNutritionist(item.photoUrl)"
                      :alt="item.firstName"
                    />
                  </c-box>
                  <c-box w="100%">
                    <c-heading
                      as="h3"
                      :marginBottom="['5px', '15px']"
                      :fontSize="['14px', '18px']"
                      :lineHeight="['21px', '27px']"
                      fontWeight="700"
                      color="black.900"
                    >
                      {{ getFullnameTitle(item) }}
                      <c-box
                        :d="['none', 'inline-block']"
                        as="span"
                        :color="item.isAvailable ? 'brand.900' : 'red.900'"
                        borderWidth="1px"
                        borderStyle="1px solid"
                        :borderColor="
                          item.isAvailable ? 'brand.900' : 'red.900'
                        "
                        borderRadius="30px"
                        px="8px"
                        py="0px"
                        fontSize="12px"
                        fontWeight="500"
                        verticalAlign="middle"
                        marginLeft="8px"
                      >
                        {{ item.isAvailable ? "Available" : "Full" }}
                      </c-box>
                    </c-heading>
                    <c-text
                      v-if="item.specialization"
                      fontFamily="Roboto"
                      :fontSize="['12px', '16px']"
                      color="brand.900"
                      :marginBottom="['6px', '8px']"
                    >
                      {{ item.specialization }}
                    </c-text>
                    <c-box :mb="[null, '20px']">
                      <c-text
                        v-for="(item, index) in item.education"
                        :key="'edu' + index"
                        fontFamily="Roboto"
                        fontSize="14px"
                        color="darkGray.900"
                        text-transform="capitalize"
                      >
                        {{ item.level }} -
                        {{
                          item.university === "0"
                            ? item.otherUniversity
                            : getUniversityName(item.university)
                        }}
                      </c-text>
                    </c-box>
                    <c-box
                      :d="['none', 'flex']"
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <c-button
                        v-if="!item.whoHandle"
                        right-icon="arrow-forward"
                        backgroundColor="secondary.800"
                        color="#927102"
                        borderRadius="100px"
                        fontSize="16px"
                        px="20px"
                        :isDisabled="!availableProgram"
                        @click.prevent="onChooseNutritionist(item.id)"
                      >
                        Pilih
                      </c-button>
                      <c-button
                        v-if="item.whoHandle"
                        right-icon="check"
                        backgroundColor="#F2F2F2"
                        color="brand.900"
                        borderRadius="100px"
                        fontSize="16px"
                        px="20px"
                      >
                        Dipilih
                      </c-button>
                      <c-text
                        fontFamily="Roboto"
                        fontSize="16px"
                        color="gray.900"
                      >
                        <c-image
                          :src="require('@/assets/icon-star.svg')"
                          alt="icon"
                          display="inline-block"
                          verticalAlign="middle"
                          marginRight="0px"
                        />
                        {{ item.rating ? item.rating : "0" }}/5
                      </c-text>
                    </c-box>
                  </c-box>
                </c-flex>
                <c-flex
                  :d="['flex', 'none']"
                  border-top="1px solid #F2F2F2"
                  py="13px"
                  px="20px"
                >
                  <c-box
                    w="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <c-flex>
                      <c-flex
                        align="center"
                        borderWidth="1px"
                        borderStyle="1px solid"
                        :color="item.isAvailable ? 'brand.900' : 'red.900'"
                        :borderColor="
                          item.isAvailable ? 'brand.900' : 'red.900'
                        "
                        borderRadius="30px"
                        px="8px"
                        py="0px"
                        fontSize="12px"
                        fontWeight="500"
                        verticalAlign="middle"
                        mr="20px"
                      >
                        {{ item.isAvailable ? "Available" : "Full" }}
                      </c-flex>
                      <c-text
                        fontFamily="Roboto"
                        fontSize="16px"
                        color="gray.900"
                      >
                        <c-image
                          :src="require('@/assets/icon-star.svg')"
                          alt="icon"
                          display="inline-block"
                          verticalAlign="middle"
                          marginRight="0px"
                        />
                        {{ item.rating ? item.rating : "0" }}/5
                      </c-text>
                    </c-flex>
                    <c-button
                      v-if="!item.whoHandle"
                      right-icon="arrow-forward"
                      backgroundColor="secondary.800"
                      color="#927102"
                      borderRadius="100px"
                      fontSize="16px"
                      px="20px"
                      :isDisabled="!availableProgram"
                      @click.prevent="onChooseNutritionist(item.id)"
                    >
                      Pilih
                    </c-button>
                    <c-button
                      v-if="item.whoHandle"
                      right-icon="check"
                      backgroundColor="#F2F2F2"
                      color="brand.900"
                      borderRadius="100px"
                      fontSize="16px"
                      px="20px"
                    >
                      Dipilih
                    </c-button>
                  </c-box>
                </c-flex>
              </c-box>
            </c-grid>

            <c-flex justifyContent="center" w="100%" marginTop="40px">
              <c-flex justifyContent="center">
                <c-button
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  backgroundColor="transparent"
                  :isDisabled="currentPage === 1"
                  @click.prevent="prevPage"
                >
                  <c-icon name="chevron-left" size="24px" />
                </c-button>

                <c-button
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  mx="4px"
                  backgroundColor="rgba(0, 140, 129, 0.3)"
                  color="brand.900"
                  v-chakra="{
                    ':hover': {
                      bg: 'rgba(0, 140, 129, 0.3)',
                      color: 'brand.900',
                    },
                  }"
                  v-for="page in pages"
                  :key="page"
                  :bg="
                    page === currentPage
                      ? ['rgba(0, 140, 129, 0.3)']
                      : ['transparent']
                  "
                  as="router-link"
                  :to="{ name: $route.name, query: { ...$route.query, page } }"
                >
                  {{ page }}
                </c-button>

                <c-button
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  backgroundColor="transparent"
                  :isDisabled="pages.length === page"
                  @click.prevent="nextPage"
                >
                  <c-icon name="chevron-right" size="24px" />
                </c-button>
              </c-flex>
            </c-flex>
          </c-box>
        </c-box>
        <c-flex v-if="isEmpty" py="80px" px="20px" justifyContent="center">
          <c-box>
            <c-image
              :src="require('@/assets/empty-nutritionists.svg')"
              alt="empty"
            />
            <c-text
              color="gray.900"
              fontFamily="Roboto"
              fontSize="24px"
              marginTop="20px"
            >
              Ahli gizi tidak ditemukan
            </c-text>
          </c-box>
        </c-flex>
      </c-box>
    </c-box>

    <c-box
      v-if="isMobileFilter"
      :display="['block', 'none']"
      pos="fixed"
      left="0"
      bottom="0"
      w="100%"
      h="100%"
      zIndex="88"
      backgroundColor="rgba(0,0,0,0.5)"
    >
      <c-box
        backgroundColor="#ffffff"
        pos="absolute"
        left="0"
        bottom="0"
        w="100%"
        h="50%"
        borderRadius="10px 10px 0 0"
      >
        <c-box padding="20px 20px" pos="relative">
          <c-text fontSize="16px" fontWeight="700" color="black.900">
            Spesialisasi
          </c-text>
          <c-button
            backgroundColor="transparent"
            pos="absolute"
            top="8px"
            right="0px"
            @click="isMobileFilter = false"
          >
            <c-icon name="close" size="10px" />
          </c-button>
        </c-box>
        <c-box
          h="100%"
          overflow="auto"
          v-chakra="{
            ul: {
              li: {
                padding: '10px 20px',
                borderBottom: '1px solid #F2F2F2',
                cursor: 'pointer',
                fontSize: '12px',
                pos: 'relative',
                '&.active': {
                  backgroundColor: 'rgba(0, 140, 129, 0.1)',
                  color: 'brand.900',
                },
              },
            },
          }"
        >
          <c-list v-if="listFilter.length > 0" spacing="0">
            <c-list-item
              v-for="item in listFilter"
              :key="item"
              :class="{
                active:
                  specialization === item ||
                  (!specialization && item === 'Semua spesialisasi'),
              }"
            >
              <c-box @click.prevent="onChangeSpecialization(item)">
                {{ item }}
              </c-box>
              <c-icon
                v-if="
                  specialization === item ||
                  (!specialization && item === 'Semua spesialisasi')
                "
                name="check"
                fontSize="12px"
                pos="absolute"
                top="13px"
                right="20px"
              />
            </c-list-item>
          </c-list>
        </c-box>
      </c-box>
    </c-box>

    <c-box v-if="isEmptyPrograms" pos="fixed" bottom="20px" right="20px">
      <c-alert status="warning">
        <c-alert-icon />
        <c-alert-description>
          Kamu belum punya program, silakan beli program terlebih dahulu.
          <c-button
            size="sm"
            variant="ghost"
            variant-color="brand.900"
            as="router-link"
            :to="{ name: 'client.index', hash: '#program' }"
          >
            Beli Program
          </c-button>
        </c-alert-description>
      </c-alert>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";

import { mapActions, mapGetters } from "vuex";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "ListNutritionistsPage",
  components: {
    Header,
    Footer,
    Breadcrumb,
  },
  mixins: [generalMixin],
  data() {
    return {
      totalData: 1,
      page: 1,
      perPage: 10,
      nutritionists: [],
      search: this.$route.query.query ?? "",
      recommendationNutritionists: [],
      timer: null,
      specialization: "",
      listFilter: [],
      isMobileFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      axios: "axios",
      isAuthenticated: "auth/isAuthenticated",
      programs: "profileProgram/history",
      universities: "general/universities",
    }),
    isEmpty() {
      return this.nutritionists.length == 0;
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10);
    },
    pages() {
      let total = this.totalData ?? 1;
      let perPage = this.perPage ?? 10;
      let length = Math.ceil(total / perPage);
      return Array.from({ length }, (_, idx) => idx + 1);
    },
    availableProgram() {
      if (this.programs.length > 0) {
        let pendingProgram = this.programs.find((dt) => dt.isLatest);
        return pendingProgram;
      }
      return false;
    },
    isEmptyPrograms() {
      if (this.programs.length == 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.listNutritionist(route.query);
        this.listRiwayatPrograms().then(() => {
          this.listRecomendationNutritionist();
        });
      },
    },
  },
  methods: {
    ...mapActions({
      listRiwayatPrograms: "profileProgram/listHistoryPrograms",
      setSuccessMessages: "clients/updateSuccessPayload",
      chooseNutritionist: "clients/chooseNutritionist",
      loadUniversities: "general/loadUniversities",
    }),
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            page: 1,
            query: this.search,
          },
        });
      }, 800);
    },
    onChangeSpecialization(e) {
      this.specialization = e;
      this.isMobileFilter = false;
      if (e === "Semua spesialisasi") {
        this.specialization = "";
      }
      this.$router.replace({
        name: this.$route.name,
        query: {
          page: 1,
          query: this.search,
          specialization: this.specialization,
        },
      });
    },
    async listNutritionist({ page = 1, query = null } = {}) {
      let params = new URLSearchParams();
      params.set("perpage", this.perPage);
      params.set("page", page);
      params.set("specialization", this.specialization);
      if (query != null) params.set("q", query);

      this.axios
        .get(`/v1/clients/nutritionist`, { params: params })
        .then((it) => it.data)
        .then((it) => {
          this.nutritionists = it.data;
          this.totalData = it.meta.total;
          this.listFilter = it.meta.filter.filter((it) => it);
          this.listFilter.unshift("Semua spesialisasi");
        });
    },
    listRecomendationNutritionist() {
      if (!this.availableProgram) return;
      this.axios
        .get(
          `/v1/clients/programs/${this.availableProgram.id}/recommendation-nutritionist`
        )
        .then((it) => it.data)
        .then((it) => {
          this.recommendationNutritionists = it.data;
        });
    },
    getFullnameTitle(item) {
      let title = [];
      if (item.education && item.education.length > 0) {
        item.education.map((dt) => title.push(dt.title));
      }
      return (
        item?.firstName + " "+item?.lastName + ` ${title ? title.join(", ") : ""}`
      );
    },
    getUniversityName(id) {
      if (this.universities.length === 0) return "";
      let a = this.universities.find((dt) => dt.id.toString() === id);
      return a.name;
    },
    onChooseNutritionist(id) {
      if (!this.availableProgram) return;
      this.chooseNutritionist({
        programId: this.availableProgram.id,
        nutritionist: {
          nutritionistId: id,
        },
      })
        .then((it) => {
          let idx = this.recommendationNutritionists.findIndex(
            (dt) => dt.id == id
          );
          if (idx > -1) {
            this.recommendationNutritionists[idx].whoHandle = true;
          } else {
            this.listRecomendationNutritionist();
          }

          this.setSuccessMessages({
            title: "Pilih Ahli Gizi",
            body: "Berhasil Pilih Ahli Gizi",
            description: "Kamu berhasil memilih ahli gizi",
          });
          this.$router
            .push({
              name: "client.success",
              query: { program_id: this.availableProgram.id },
            })
            .catch();
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message || "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
    prevPage() {
      this.page -= 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
    nextPage() {
      this.page += 1;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      });
    },
  },
  mounted() {
    this.loadUniversities();
    if (!this.isAuthenticated) return this.$router.push({ name: "auth.login" });
  },
};
</script>

<style>
</style>
